<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl mt-5 font-bold text-blue-800">
        SendInBlue Email Sync
      </h1>
      <div class="flex flex-wrap xl:-mx-4 pb-5">
        <div class="w-full xl:my-4 xl:px-4">
          <p class="text-red-800" v-if="errorMssg">
            {{ errorMssg }}
          </p>
        </div>

        <div class="w-1/2 xl:my-4 xl:px-4">
          <div class="w-full p-5 border-2 border-gray-100 bg-white rounded-lg">
            <div class="flex flex-wrap xl:-mx-4  pb-5">
              <!-- <div class="w-full"> -->
              <div class="flex w-full">
                <div class="m-3 w-full">
                  <p class="text-sm font-bold mb-3">
                    Email Address
                  </p>
                  <p class="text-sm font-semibold">
                    <textarea
                      rows="5"
                      class="inline-block p-3 ring-1 w-11/12 outline-none ring-gray-300 rounded-sm w-80 text-sm"
                      placeholder="Enter Emails separated by commas"
                      v-model="emails"
                    />
                  </p>
                </div>
              </div>
              <div class="w-full flex mt-5">
                <div class="ml-3 w-80">
                  <button
                    class="py-3 mr-5 px-10 bg-yellow-300 rounded-md font-semibold text-black text-sm"
                    @click="emailPush"
                  >
                    Synch
                  </button>
                  <button
                    class="py-3 px-10 bg-blue-800 rounded-md font-semibold text-white text-sm"
                    @click="synchAll"
                  >
                    Synch All
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      errorMssg: "",
      message: "",
      emails: "",
    };
  },
  async mounted() {},
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    async emailPush() {
      //
      if (this.emails !== "") {
        this.isLoading = true;
        try {
          let res = await this.$store.dispatch("BULK_UPLOAD_EMAILS", {
            emails: this.emails,
          });
          if (res.status) {
            alert(res.message);
            this.emails = "";
          } else {
            alert(res.message);
            this.isLoading = false;
          }
        } catch (error) {
          console.log(error);
        }
        this.isLoading = false;
      } else {
        alert("Email field cannot empty");
      }
    },

    async synchAll() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("EMAIL_SYNCH_ALL");
        if (res.status) {
          alert(res.message);
        } else {
          alert(res.message);
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.tel::-webkit-inner-spin-button,
.tel::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.tel {
  -moz-appearance: textfield;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
